export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"src":"https://www.googletagmanager.com/gtag/js?id=G-RTPGH2PR10","async":true,"id":"gtag","type":"text/javascript"}],"noscript":[]}

export const appPageTransition = {"enterActiveClass":"transition-opacity duration-200 ease-out","enterFromClass":"opacity-0","enterToClass":"opacity-100","leaveActiveClass":"transition-opacity duration-75 ease-in","leaveFromClass":"opacity-100","leaveToClass":"opacity-0"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null